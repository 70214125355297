import React, { useState, useRef } from 'react'
import UploadVideoDetails from '../../components/UploadVideoDetails/UploadVideoDetails';
import FileUpload from '../../components/FileUpload/FileUpload';
import AuthPageWrapper from '../../components/AuthWrapper/AuthPageWrapper';
import "./upload.css"

export default function Upload() {
    const [videos, setVideos] = useState([]);

    const inputRef = useRef();

    const handleRemove = (id) => {
        setVideos(files => files.filter(file => file.id !== id))
    }

    const handleUpdateCB = (id) => {
        return function (update) {
            if (typeof (update) === 'object')
                setVideos(files => files.map(file => (file.id === id) ? Object.assign(file, update) : file))
        }
    }


    return (
        <AuthPageWrapper roles={['ContentManager']}>
            <div className='video-page-wrepper'>
                <div className='video-page'>
                    <div className='page-header'>
                        העלאת סרטון
                    </div>
                    <hr />
                    <div className='page-container'>
                        <div className='file-upload-wrapper'>
                            <FileUpload uploadedFiles={videos} setUploadedFiles={setVideos} inputRef={inputRef} />
                        </div>
                        <div>
                            <ul className='files-preview'>
                                <ul>
                                    {videos?.map(video =>
                                        <UploadVideoDetails key={video.id} url={video.url} {...video} handleRemove={handleRemove} handleUpdate={handleUpdateCB(video.id)} />
                                    )}
                                </ul>
                            </ul>
                        </div>
                        <div>
                            {!(videos.length) && <button onClick={() => { inputRef.current.click() }} className='upload-button'>בחירת קובץ</button>}
                        </div>

                    </div>
                </div>
            </div>
        </AuthPageWrapper>
    )
}
