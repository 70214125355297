import React from 'react';
import Header from './components/Header/Header';
import { Outlet } from 'react-router-dom';

import { menuItems } from './service/AppContext';

export default function Layout() {
    return (
        <>
            <Header menuItems={menuItems} />
            <div id="page-wrap" style={{paddingTop:'56px', direction:'rtl'}} >
                <Outlet />
            </div>
        </>
    );
}
