import React from "react";
import { slide as Menu } from "react-burger-menu";
import "./Sidebar.css";
import { Link } from "react-router-dom";

const Sidebar = ({ links,isOpen,handleCloseSidebar }) => {
 
  return (
    <Menu isOpen={isOpen} customBurgerIcon={false} width={'150px'} pageWrapID={"page-wrap"} outerContainerId={"outer-container"} onClose={handleCloseSidebar} >

      <Link id="home" className="menu-item" to="/" onClick={handleCloseSidebar}>דף הבית</Link>
      {links?.map(link => <Link key={link.label} className="menu-item" to={link.to} onClick={handleCloseSidebar} >{ link.label}</Link>)}
    </Menu>
  );
};

export default Sidebar;
