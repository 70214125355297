import React from 'react'
import './notFound.css' 
export default function NotFound() {
    return (
        <div className="fill" >
            <div className='center-content'>
            <h3 >דף אינו קיים</h3>
            </div>
        </div>
  )
}
