import React, { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { UploadIcon } from '../../assets/images';
import './fileUpload.css';
import { appContext } from '../../service/AppContext';
import { v4 } from 'uuid';
import { json } from 'react-router-dom';

const FileUpload = ({ uploadedFiles, setUploadedFiles, inputRef }) => {

    const { breslevRequest } = useContext(appContext);



    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: false,
        accept: {
            'video/mp4': ['.mp4', '.MP4'],
            // "video/mpeg": [".mpeg"],        
        },

        onDrop: async (acceptedFiles) => {

            if (acceptedFiles.length) {
                let id = v4();
                const firstController = new AbortController();
                const controller = new AbortController();
                setUploadedFiles(acceptedFiles.map(file => Object.assign(file,
                    {
                        id,
                        preview: URL.createObjectURL(file),
                        // fileName:file.name,
                        title: file.name.substring(0, file.name.lastIndexOf('.')) || file.name,
                        controller,
                        inputRef,
                        progress: 0,
                    })));

                // create a new FormData object and append the file to it
                const formData = new FormData();
                // for (const file in acceptedFiles)
                formData.append(id, acceptedFiles[0]);

                let firstResponse = await breslevRequest({
                    signal: firstController.signal,
                    method: 'Post',
                    route: "/video/pre-upload",
                    body: JSON.stringify({ videos: acceptedFiles.map(file => { return { id: file.id, title: file.title } }) }),
                    headers: { "Content-Type": "application/json" },
                });
                if (firstResponse.status === 201) {
                    console.log(id)
                }
                let response = await breslevRequest({
                    signal: controller.signal,
                    method: 'Post',
                    route: "/video/upload",
                    body: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                    params: { videos: acceptedFiles.map(file => { return { id: file.id, title: file.title } }) },
                    onUploadProgress: ({ loaded, total }) => setUploadedFiles(prev => prev.map(file => (file.id === id) ? { ...file, progress: Math.floor((loaded * 100) / total) } : file))
                });
                if (response?.status === 201 && response?.data) {
                    let savedUrl = response.data?.saved_url;
                    for (const id in savedUrl) {
                        setUploadedFiles(prev => prev.map(file => (file.id === id) ? Object.assign(file, { url: savedUrl[id] }) : file));
                    }
                }
            }

        },
    });



    return (
        <div>
            <div {...getRootProps()}>
                <input {...getInputProps()} ref={inputRef} />
                {(uploadedFiles.length === 0) &&
                    <>
                        <div className='upload-icon'>
                            <img className={(isDragActive) ? 'dragging' : ''} src={UploadIcon} alt='upload icon' />
                        </div>
                        <div className='label'>
                            יש לגרור ולשחרר קובץ סרטון להעלאה
                        </div>
                    </>
                }
            </div>


        </div>
    );
};
export default FileUpload;