import React, { useState, useContext } from 'react';

import { appContext } from '../../service/AppContext';

export default function DeleteUser({ user_id, email, onCancel, onConfirm }) {

    const [validate, setValidate] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const { breslevRequest } = useContext(appContext);

    const handleChangeValidate = (e) => {
        setValidate(e.target.value);
    }

    const handleConfirm = async () => {
        if (validate === email) {
            let response = await breslevRequest({method:'delete', route: "/users/delete/" + user_id });
            if (response?.status === 204) {
                setErrorMsg('');
                onConfirm();
            }
            else
                setErrorMsg('אירעה שגיאה');
        }

        else
            setErrorMsg('הזנה שגויה');
    }

    return (
        <div className="layout-modal">
            <div className="layout-modal__backdrop"></div>
            <div className="panel">
                <div className="panel__header with-border">
                    <h2>מחיקת משתמש?</h2>
                    <div onClick={onCancel} className="layout-modal__close">
                        <div>
                            <img
                                src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/close.svg"
                                alt="Close Modal"
                            />
                        </div>
                    </div>
                </div>
                <div className="panel__body">
                    <div className="user-detail-form">
                        <p>
                            כדי למחוק את המשתמש, נא לאשר על ידי הקלדת מזהה הדוא"ל של המשתמש:
                            <br />
                            <span>{email}</span>
                        </p>
                        <div className="user-delete-input-container">
                            <div className="input-field-container">
                                <div className="input-field-inset">
                                    <input
                                        type="text"
                                        name="input"
                                        className="text-small text-black input-field"
                                        value={validate}
                                        onChange={handleChangeValidate}
                                    />

                                    <div className="input-field-suffix"></div>
                                </div>
                                {(!!errorMsg) && <div className="input-field-error block-small block-error">
                                    <img
                                        className="input-field-error-icon"
                                        src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/form-field-error-icon.svg"
                                        alt="Error in field"
                                    />
                                    <p className="input-field-error-text text-small text-error">{errorMsg}</p>
                                </div>}
                            </div>
                        </div>
                        <div className="user-detail-form__actions">
                            <button onClick={onCancel} className="button outline">ביטול</button>
                            <button onClick={handleConfirm} className="button button-error" disabled={(validate !== email)}>
                                מחק לעד
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
