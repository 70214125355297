import React, { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApiDomain } from "../config";
import axios from "axios";
import { toast } from 'react-toastify';


export const appContext = createContext()

export const menuItems = [
  { to: 'upload', label: "העלאת סרטון", roles: ['ContentManager'] },
  { to: 'videos', label: "כל הסרטונים", roles: ['ContentManager', 'Admin'] },
  { to: 'managment', label: "ניהול משתמשים", roles: ['Admin'] },
]

export const statusLabelMaper = { 0: 'העלאה..', 1: 'ממתין לעיבוד', 2: 'עיבוד..', 3: 'מוכן', 8: 'חלקי', 9: 'עיבוד נכשל' }
export const roleLabelMaper = { Admin: 'מנהל מערכת', ContentManager: 'מנהל תוכן' }

export default function AppContext({ children }) {

  const navigate = useNavigate();


  async function breslevRequest(options = {}) {
    const defaultOptions = { method: 'GET', route: '/', body: {}, headers: {}, params: {} }
    const { method, route, body, headers, params, ...rest } = { ...defaultOptions, ...options }

    try {
      let response;

      switch (method.toUpperCase()) {
        case 'POST':
          response = await axios.post(getApiDomain() + route, body, { headers: { ...headers }, params: { ...params }, ...rest });
          break;

        case 'PUT':
          response = await axios.put(getApiDomain() + route, body, { headers: { ...headers }, params: { ...params }, ...rest });
          break;

        case 'DELETE':
          response = await axios.delete(getApiDomain() + route, body, { headers: { ...headers }, params: { ...params }, ...rest });
          break;

        case 'GET':
        default:
          response = await axios.get(getApiDomain() + route, { headers: { ...headers }, params: { ...params }, ...rest });
          break;

      }
      return response
    }
    catch (err) {
      if (err?.response) {
        if (err?.response?.data && (typeof (err.response.data) === 'object')) {
          if ('msg' in err.response.data)
            toast(err.response.data.msg);
          else if ('message' in err.response.data && err.response.data.message === 'invalid claim')
            toast('פעולה לא מורשת');
        }
        return err.response
      }
      else {
        console.error(err?.response?.data)
        toast('שגיאת חיבור')
      }
    }

  }


  const value = { navigate, breslevRequest }

  return (
    <appContext.Provider value={value}>
      {children}
    </appContext.Provider>
  )
}
