import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import './login.css'
import { signIn } from "supertokens-web-js/recipe/emailpassword";
import Peek from '../../components/Peek/Peek';
// import Input from '../../components/Input/Input';

export default function Login() {
    const [errMsg, setErrMsg] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [peekPass, setPeekPass] = useState(false)
    
    const handleSetPeek = () => {
        setPeekPass(prev=>!prev)    
    }

    const navigate = useNavigate();

    async function signInClicked(e) {
        e.preventDefault();
        try {
            let response = await signIn({
                formFields: [{
                    id: "email",
                    value: email
                }, {
                    id: "password",
                    value: pass
                }]
            })

            if (response.status === "FIELD_ERROR") {
                response.formFields.forEach(formField => {
                    if (formField.id === "email") {
                        // Email validation failed (for example incorrect email syntax).
                        setErrMsg(formField.error)
                    }
                })
            } else if (response.status === "WRONG_CREDENTIALS_ERROR") {
                setErrMsg("שילוב שם משתמש או סיסמה שגויים")
            } else if (response.status === "SIGN_IN_NOT_ALLOWED") {
                // this can happen due to automatic account linking. Tell the user that their 
                // input credentials is wrong (so that they do through the password reset flow)
                setErrMsg("שם משתמש או סיסמה שגויים")
            } else {
                // sign in successful. The session tokens are automatically handled by
                // the frontend SDK.
                navigate('/');
            }
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.
                setErrMsg(err.message);
            } else {
                setErrMsg("אופס, אירעה שגיאה");
            }
        }
    }
    
    const handleResetPasswordClicked = () => {
        navigate('/reset-password');
    }

    return (
        <div data-supertokens="container" >
            <div data-supertokens="row">
                <div data-supertokens="headerTitle">כניסה</div>
                <div data-supertokens="headerSubtitle"></div>
                <div data-supertokens="divider"></div>
                {(!!errMsg) &&<div data-supertokens="generalError">{errMsg}</div>}
                <form autoComplete="on" noValidate="" >
                    <div data-supertokens="formRow ">
                        <div data-supertokens="label">אימייל</div>
                        <div data-supertokens="inputContainer">
                            <div data-supertokens="inputWrapper ">
                                {/* <Input type="email" name="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} /> */}
                                <input
                                    autoComplete="email"
                                    data-supertokens="input"
                                    className="supertokens-input"
                                    type="email"
                                    name="email"
                                    placeholder="Email address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div data-supertokens="formRow ">
                        <div data-supertokens="label">סיסמה</div>
                        <div data-supertokens="inputContainer">
                            <div data-supertokens="inputWrapper ">
                                <input
                                    autoComplete="current-password"
                                    data-supertokens="input"
                                    className="supertokens-input"
                                    type={peekPass?"text":"password"}
                                    name="password"
                                    placeholder="Password"
                                    value={pass}
                                    onChange={(e)=>setPass(e.target.value)}
                                />
                                {(!!pass.length > 0) && <Peek onClick={handleSetPeek} peek={peekPass}/>}
                            </div>
                        </div>
                    </div>
                    <div data-supertokens="formRow ">
                        <button onClick={signInClicked} type="submit" data-supertokens="button">כניסה</button>
                        <div onClick={handleResetPasswordClicked} data-supertokens="link secondaryText forgotPasswordLink">
                            שכחת את הסיסמה?
                        </div>
                    </div>
                </form>
            </div>

        </div>

    )
}
