import React from 'react'
import './allVideos.css'
import VideosTable from '../../components/VideosTable/VideosTable'
import AuthPageWrapper from '../../components/AuthWrapper/AuthPageWrapper'
export default function AllVideos() {
    return (
        <AuthPageWrapper roles={['Admin','ContentManager']}>

            <div className='video-page-wrepper'>
                {/* <div className='video-page'> */}
                    <div className='page-header'>
                        כל הסרטונים
                    </div>
                    <hr />
                    <div className='page-container'>
                        <VideosTable />

                    </div>
                {/* </div> */}
            </div>
        </AuthPageWrapper>
    )
}
