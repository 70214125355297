import React, { useContext, useState } from 'react'
import { sendPasswordResetEmail } from "supertokens-web-js/recipe/emailpassword";
import { appContext } from '../../service/AppContext';
import ResetMassage from './ResetMassage';
export default function ResetPassword() {

    const [email, setEmail] = useState('')
    const [sent, setSent] = useState(false)

    const { navigate } = useContext(appContext);

    const handleBackClick = () => {
        setSent(false)
    }
    const handleLoginClick = () => {
        navigate('/auth')
    }

    const handleResetPassword = async (e) => {
        e.preventDefault()
        let response = await sendPasswordResetEmail({
            formFields: [{
                id: "email",
                value: email
            }]

        })
        if (response.status in ["FIELD_ERROR", "PASSWORD_RESET_NOT_ALLOWED"])
            console.error(response)
        else
            setSent(true)
    }

    return (

        <div data-supertokens="container">
            {(sent) ?
                <ResetMassage email={email} handleBackClick={handleBackClick} handleLoginClick={handleLoginClick} />
                :
                <div data-supertokens="row">
                    <div data-supertokens="headerTitle resetPasswordHeaderTitle">
                        <button onClick={handleLoginClick} data-supertokens="backButton backButtonCommon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="13"
                                viewBox="0 0 16 13"
                                data-supertokens="heavyArrowLeftIcon"
                            >
                                <path
                                    fill="rgb(var(--palette-textTitle))"
                                    d="M13 6.8h.022H3.8l2.9 2.9a.761.761 0 0 1 0 1.07l-.451.451a.754.754 0 0 1-1.064 0L.22 6.254a.759.759 0 0 1 0-1.068L5.186.22a.755.755 0 0 1 1.064 0l.45.451a.746.746 0 0 1 .22.532.724.724 0 0 1-.22.522l-2.93 2.92h9.24a.781.781 0 0 1 .764.773v.638A.766.766 0 0 1 13 6.8z"
                                    transform="translate(1.182 .708)"
                                ></path>
                            </svg>
                        </button>איפוס סיסמה<span data-supertokens="backButtonPlaceholder backButtonCommon"></span>
                    </div>
                    <div data-supertokens="headerSubtitle">
                        <div data-supertokens="secondaryText">
                            אנו נשלח אליך מייל לאיפוס הסיסמה
                        </div>
                    </div>
                    <form autoComplete="on" noValidate="">
                        <div data-supertokens="formRow ">
                            <div data-supertokens="label">אימייל</div>
                            <div data-supertokens="inputContainer">
                                <div data-supertokens="inputWrapper ">
                                    <input
                                        autoComplete="email"
                                        data-supertokens="input"
                                        className="supertokens-input"
                                        type="email"
                                        name="email"
                                        placeholder=""
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div data-supertokens="formRow ">
                            <button onClick={handleResetPassword} type="submit" data-supertokens="button">שלח אליי</button>
                        </div>
                    </form>
                </div>
            }
        </div>
    )
}
