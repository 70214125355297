import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { toLocalDate } from '../UserManagment/User'
import { useNavigate } from 'react-router-dom'
import Input from '../../components/Input/Input'
import { sendPasswordResetEmail } from "supertokens-web-js/recipe/emailpassword";
import DeleteUser from '../UserManagment/DeleteUser';
import { toast } from 'react-toastify';
import { appContext } from '../../service/AppContext'

import { roleLabelMaper } from '../../service/AppContext'


export default function UserDetails() {

  const [details, setDetails] = useState()
  const [editDetails, setEditDetails] = useState({ first_name: '', last_name: '', roles: [] })
  const [edit, setEdit] = useState(false)
  const [deleteUser, setDeleteUser] = useState(false);

  const { breslevRequest } = useContext(appContext);

  const params = useParams()
  const navigate = useNavigate();


  const badge = () => {
    if (details?.first_name && details?.last_name)
      return details.first_name[0].toUpperCase() + details.last_name[0].toUpperCase()
    if (details?.email)
      return details?.email.slice(0, 2).toUpperCase()
  }

  useEffect(() => {
    async function getUserDetails(id) {
      let response = await breslevRequest({ route: "/users/details/" + id });
      if (response?.status === 200 && response?.data) {
        setDetails(response.data);
        setEditDetails(response.data);
      }

    }
    getUserDetails(params?.user_id);
  }, [params])


  const handleNavBack = () => {
    navigate('/managment')
  }
  const handleDeletedUser = () => {
    navigate('/managment')
    toast('מייל איפוס סיסמה נשלח')
  }

  const handleUpdateRoles = (e) => {
    let roles = [...e.target.selectedOptions].map(opt => opt.value)
    setEditDetails((prev) => { return { ...prev, roles } });
  }

  const handleSave = async () => {
    let response = await breslevRequest({ method: 'put', route: "/users/details/" + params?.user_id, body: editDetails });
    if (response?.status === 200 && response?.data) {
      setDetails(response.data);
      setEditDetails(response.data);
      setEdit(false)
      toast('פרטי משתמש עודכנו')
    }
  }

  const handleCancelEdit = () => {
    setEdit(false)
    setEditDetails(details)
  }

  const handleResetPassword = async () => {
    let response = await sendPasswordResetEmail({
      formFields: [{
        id: "email",
        value: details.email
      }]

    })
    if (response.status in ["FIELD_ERROR", "PASSWORD_RESET_NOT_ALLOWED"])
      console.error(response)
    else
      toast('מייל איפוס סיסמה נשלח')

  }

  const handleEditDetailsCB = (keyName) => {
    return function (e) {
      setEditDetails(prev => { return { ...prev, [keyName]: e.target.value } })
    }
  }



  return (
    <div className="user-detail">
      <div className="user-detail__navigation">
        <button className="button flat" onClick={handleNavBack} >
          <img style={{ transform: 'scaleX(-1)', marginLeft: '0.5em' }}
            src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/left-arrow-dark.svg"
            alt="חזרה לרשימת משתמשים"
          /><span>חזרה לרשימת משתמשים</span>
        </button>
      </div>
      <div className="user-detail__header">
        <div className="user-detail__header__badge">{badge()}</div>
        <div className="user-detail__header__info">
          <div className="user-detail__header__title">
            <span><span>{details?.email}</span></span>
          </div>
          <div className="user-detail__header__user-id">
            <span className="user-detail__header__user-id__label">User ID:</span>
            <span
              className="user-detail__header__user-id__text block-snippet-large"
            ><span className="copy-text">
                <span className="copy-text-text">{params.user_id}</span>
                <span className="copy-text-action">
                  <img
                    src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/copy.svg"
                    alt="Copy text to clipboard" />
                </span>
              </span>
            </span>
          </div>
        </div>
        <div className="user-detail__header__action">
          <button onClick={() => setDeleteUser(true)} className="button button-error">מחיקה</button>
        </div>
      </div>
      <div className="panel">
        <div className="panel__header with-border">
          <div className="title">תפקידים</div>
        </div>
        <div className="panel__body">
          <div className="tenant-list-container">
            {(edit) ?
              <select name='roles' onChange={handleUpdateRoles} multiple defaultValue={editDetails?.roles} >
                {Object.keys(roleLabelMaper).map((key) => <option key={key} value={key} >{roleLabelMaper[key]}</option>)}
              </select>
              :
              (details?.roles) && details.roles.map((role, idx) => <div className="tenant-pill" key={idx}>{roleLabelMaper[role]}</div>)

              /* {details?.tenant_ids?.map((tenant, idx) => <div className="tenant-pill" key={idx}>{tenant}</div>)} */
            }
          </div>
        </div>
      </div>
      <div className="user-detail__info-grid">
        <div className="panel">
          <div className="panel__header with-border">
            <div className="title">פרטי משתמש</div>
            {(edit) ?
              <div className="actions">
                <button className="button outline small" onClick={handleCancelEdit}>ביטול</button>
                <button className="button link outline small" onClick={handleSave}>שמירה</button>
              </div>
              :
              <button className="button flat link small" onClick={() => { setEdit(true) }}>עריכת פרטים</button>
            }
          </div>
          <div className="panel__body">
            <div className="user-detail__info-grid__grid">
              <div className="user-detail__info-grid__item">
                <div className="user-detail__info-grid__item__label">
                  שם פרטי:
                  <div className="tooltip-container">
                    <span className="user-detail__info-grid__item__guide">
                      <img
                        src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/help-icon.png"
                        alt="First Name: guideline" />
                    </span>
                  </div>
                </div>
                <div className="user-detail__info-grid__item__body">
                  {edit ? <Input name="first_name" value={editDetails.first_name} onChange={handleEditDetailsCB('first_name')} /> : details?.first_name}
                </div>
              </div>
              <div className="user-detail__info-grid__item">
                <div className="user-detail__info-grid__item__label">
                  שם משפחה:
                  <div className="tooltip-container">
                    <span className="user-detail__info-grid__item__guide">
                      <img
                        src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/help-icon.png"
                        alt="Last Name: guideline" />
                    </span>
                  </div>
                </div>
                <div className="user-detail__info-grid__item__body">
                  {edit ? <Input name="last_name" value={editDetails.last_name} onChange={handleEditDetailsCB('last_name')} /> : details?.last_name}
                </div>
              </div>
              <div className="user-detail__info-grid__item">
                <div className="user-detail__info-grid__item__label">נרשם ב:</div>
                <div className="user-detail__info-grid__item__body">
                  {!!(details?.time_joined) && toLocalDate(details.time_joind)}
                </div>
              </div>
              <div className="user-detail__info-grid__item">
                <div className="user-detail__info-grid__item__label">Email ID:</div>
                <div className="user-detail__info-grid__item__body">
                  <span className="user-detail__provider-box__user-id">
                    <span className="copy-text">
                      <span className="copy-text-text">{details?.email}</span>
                      <span className="copy-text-action">
                        <img
                          src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/copy.svg"
                          alt="Copy text to clipboard" />
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div className="user-detail__info-grid__item">
                <div className="user-detail__info-grid__item__label">סיסמה:</div>
                <div className="user-detail__info-grid__item__body">
                  <button className="flat link" onClick={handleResetPassword} >איפוס סיסמה</button>
                </div>
              </div>
              <div className="user-detail__info-grid__item">
                <div className="user-detail__info-grid__item__label">זיהוי באמצעות</div>
                <div className="user-detail__info-grid__item__body">
                  <div className="pill emailpassword false">
                    <span>Email password</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(deleteUser) && <DeleteUser user_id={params?.user_id} email={details?.email} onCancel={() => setDeleteUser(false)} onConfirm={handleDeletedUser} />}
    </div>
  )
}
