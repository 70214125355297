import "./App.css";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home/home";
import { PreBuiltUIList, SuperTokensConfig } from "./config";
import Layout from "./Layout";
import UserManagment from "./pages/UserManagment/UserManagment";
import UserDetails from "./pages/UserDetails/UserDetails";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import AppContext from "./service/AppContext";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Upload from "./pages/Upload/Upload";
import AllVideos from "./pages/AllVideos/AllVideos";
import NotFound from "./pages/NotFound/NotFound";
SuperTokens.init(SuperTokensConfig);

function App() {

    return (
        <SuperTokensWrapper>

            <div id="outer-container">
            <ToastContainer rtl={true} />
                <Router>
                    <AppContext>
                            <Routes>
                                <Route element={<Layout />}>

                                    {/* This shows the login UI on "/auth" route */}
                                    <Route path="/auth" element={<Login />} />
                                    <Route path="/reset-password" element={<ResetPassword />} />
                                    <Route path="/" element={<Home />} />
                                    <Route path="/managment" element={<SessionAuth><UserManagment /></SessionAuth>} />
                                    {getSuperTokensRoutesForReactRouterDom(require("react-router-dom"), PreBuiltUIList)}
                                    <Route path="/users/:user_id" element={<SessionAuth><UserDetails /></SessionAuth>} />
                                    <Route path="/upload" element={<SessionAuth><Upload /></SessionAuth>} />
                                    <Route path="/videos" element={<SessionAuth><AllVideos /></SessionAuth>} />
                                    <Route path="*" element={<SessionAuth><NotFound/></SessionAuth>} />
                                </Route>
                            </Routes>
                    </AppContext>
                </Router>
            </div>
        </SuperTokensWrapper>
    );
}

export default App;
