import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";

function isProduction() {
    return (process.env.NODE_ENV === 'production')
}


export function getApiDomain() {

    const apiPort = process.env.REACT_APP_API_PORT || 3001;
    const apiUrl = (isProduction() && process.env.REACT_APP_API_URL) ? process.env.REACT_APP_API_URL : `http://localhost:${apiPort}`;
    return apiUrl;
}

export function getWebsiteDomain() {
    const websitePort = process.env.REACT_APP_WEBSITE_PORT || 3000;
    const websiteUrl = (isProduction() && process.env.REACT_APP_WEBSITE_URL) ? process.env.REACT_APP_WEBSITE_URL : `http://localhost:${websitePort}`;
    return websiteUrl;
}

export const SuperTokensConfig = {
    appInfo: {
        appName: "SuperTokens Demo App",
        apiDomain: getApiDomain(),
        websiteDomain: getWebsiteDomain(),
        apiBasePath: (isProduction() && process.env.REACT_APP_API_BASE_PATH) ? process.env.REACT_APP_API_BASE_PATH : 'auth',
    },
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [EmailPassword.init(), Session.init()],
};

export const recipeDetails = {
    docsLink: "https://supertokens.com/docs/emailpassword/introduction",
};

export const PreBuiltUIList = [EmailPasswordPreBuiltUI];

export const ComponentWrapper = (props) => {
    return props.children;
};
