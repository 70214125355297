import React, { useContext, useEffect, useState } from 'react';
import User from './User';
import SignUp from './Signup';
import { toast } from 'react-toastify';
import './ui.css';
import { appContext } from '../../service/AppContext';
import AuthPageWrapper from '../../components/AuthWrapper/AuthPageWrapper';

export default function UserManagment() {

  const [users, setUsers] = useState()
  const [addUser, setAddUser] = useState(false)

  const { breslevRequest } = useContext(appContext)

  useEffect(() => {
    async function getUsers() {
      let response = await breslevRequest({ route: "/users/get" });
      if (response?.status === 200 && response?.data)
        setUsers(response.data);
    }
    getUsers();
  }, [addUser])

  const removeUserCB = (id) => {
    return function () { setUsers(prev => prev.filter(user => user.user_id !== id)) }
  }


  return (
    <AuthPageWrapper roles={['Admin']}>
      <div className="users-list">
        <img className="title-image" src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/ST_full_logo_light_theme.svg" alt="Auth Page" />
        <h1 className="users-list-title">ניהול משתמשים</h1>
        <p className="text-small users-list-subtitle">מרכז ניהול משתמשים, הרשאות גישה וכל מידע רלוונטי.</p>
        {(addUser) ? <SignUp setAddUser={setAddUser} /> :
          <div>
            <button className='add-user-button' onClick={() => setAddUser(true)}>הוספת משתמש</button>
            <div className="users-list-paper">
              <div className="users-list-table-container">
                <table className="users-list-table">
                  <thead>
                    <tr>
                      <th>משתמש</th>
                      <th>תפקידים</th>
                      <th>תאריך הצטרפות</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="text-small">
                    {users?.map(user => <User key={user.user_id} removeUser={removeUserCB(user.user_id)}  {...user} />)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
        <div className="notification-container"></div>
      </div>
    </AuthPageWrapper>
  )

}
