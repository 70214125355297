import Session from "supertokens-auth-react/recipe/session";
import { UserRoleClaim } from "supertokens-auth-react/recipe/userroles";

export default function AuthMenuWrapper({roles=[],children}) {
    let claimValue = Session.useClaimValue(UserRoleClaim)
    if (claimValue.loading || !claimValue.doesSessionExist) {
        return null;
    }
    let userRoles = claimValue.value;
    if (Array.isArray(userRoles) && userRoles.some((role)=> roles.includes(role))) {
        //Allowed for this role 
        return children
    } else {
        // User doesn't have any roles, or is not allowed..
        return null
    }
}
