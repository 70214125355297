import React, { useContext, useState, useCallback } from 'react';
import { appContext } from '../../service/AppContext';
import { toast } from 'react-toastify';
import Input from '../Input/Input';
import { debounce } from 'lodash';

export default function UploadVideoDetails({ id, title, preview, description, handleUpdate, handleRemove, url, progress, controller, inputRef }) {


    const [videoTitle, setVideoTitle] = useState(title)
    const [videoDesciption, setVideoDesciption] = useState(description ? description : '')
    const { breslevRequest } = useContext(appContext);


    const handleChangeTitle = (event) => {
        setVideoTitle(event.target.value);
        handleUpdateByElementName(event);
    }
    const handleChangeDescription = (event) => {
        setVideoDesciption(event.target.value);
        handleUpdateByElementName(event);
    }

    const handleUpdateByElementName = useCallback(debounce(async (event) => {
        let keyName = event.target.name;
        let updateObj = { id: id, [keyName]: event.target.value }
        let response = await breslevRequest({ method: 'put', route: '/video/update', body: updateObj })
        if (response?.status === 204) {
            if (keyName === 'title')
                toast('שם הסרטון עודכן');
            if (keyName === 'description')
                toast('תיאור הסרטון עודכן');
            handleUpdate(updateObj);
        }
        else
            toast('שגיאה בעדכון פרטי סרטון');
    }, 1000, { leading: false, trailing: true })
        , [id])

    const handleRemoveElement = (async () => {
        if (progress === 100) {
            let response = await breslevRequest({ method: 'delete', route: '/video/delete/' + id })
            if (response?.status === 204) {
                toast('הסרטון הוסר בהצלחה');
                handleRemove(id);
                if (inputRef)
                    inputRef.current.value = null
            }
        }
        // else {
        //     controller.abort();
        //     toast('העלאת הסרטון בוטלה');
        //     handleRemove(id);
        //     if (inputRef)
        //         inputRef.current.value = null
        // }
    })

    return (
        <li >
            <div className='video-wrapper' >
                <video width={'100%'} controls>
                    <source src={preview} />
                </video>
                {(progress !== 100) &&
                    <div className='progress-bar'>
                        <div className='progress' style={{ width: progress + '%' }}></div>
                    </div>
                }
                {(progress === 100) && <button className='remove-file' onClick={handleRemoveElement} >X</button>}
            </div>
            <div>
                <Input name='title' value={videoTitle} onChange={handleChangeTitle} />
                <textarea name='description' rows={3} placeholder='תיאור הסרטון' onChange={handleChangeDescription} value={videoDesciption} />
                {(!!url) && <a href={url} target='_blank' rel="noreferrer">קישור לצפייה בסרטון</a>}
            </div>
        </li>
    )
}
