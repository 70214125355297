import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify';
import AuthPageWrapper from '../../components/AuthWrapper/AuthPageWrapper';
import { appContext } from '../../service/AppContext';

// import Input from '../../components/Input/Input';

export default function SignUp({ setAddUser }) {
    const [errMsg, setErrMsg] = useState('');
    const [email, setEmail] = useState('');

    const { breslevRequest } = useContext(appContext)

    async function signUpClicked(e) {
        e.preventDefault();
        if (!/([A-Za-z0-9]+[+.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+/.test(email)) {
            setErrMsg('מייל לא תקין');
            return
        }            
        let response = await breslevRequest({
            method: 'post', route: "/users/create", body: JSON.stringify({ email }), headers: { "Content-Type": "application/json" }
        });
        if (response?.status >= 200  && response?.status < 300 && response?.data) {
            toast(response.data);
            setAddUser(false)
        }
        else {
            setErrMsg(response.data);
        }
    }


    return (
        <AuthPageWrapper roles={['Admin']}>
            <div data-supertokens="container" >

                <div data-supertokens="row">
                    <div data-supertokens="headerTitle">
                        <button onClick={() => { setAddUser(false) }} data-supertokens="backButton backButtonCommon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="13"
                                viewBox="0 0 16 13"
                                data-supertokens="heavyArrowLeftIcon"
                            >
                                <path
                                    fill="rgb(var(--palette-textTitle))"
                                    d="M13 6.8h.022H3.8l2.9 2.9a.761.761 0 0 1 0 1.07l-.451.451a.754.754 0 0 1-1.064 0L.22 6.254a.759.759 0 0 1 0-1.068L5.186.22a.755.755 0 0 1 1.064 0l.45.451a.746.746 0 0 1 .22.532.724.724 0 0 1-.22.522l-2.93 2.92h9.24a.781.781 0 0 1 .764.773v.638A.766.766 0 0 1 13 6.8z"
                                    transform="translate(1.182 .708)"
                                ></path>
                            </svg>
                        </button>&nbsp;רישום משתמש חדש
                    </div>
                    <div data-supertokens="headerSubtitle"></div>
                    <div data-supertokens="divider"></div>
                    {(!!errMsg) && <div data-supertokens="generalError">{errMsg}</div>}
                    <form autoComplete="on" noValidate="" >
                        <div data-supertokens="formRow ">
                            <div data-supertokens="label">אימייל</div>
                            <div data-supertokens="inputContainer">
                                <div data-supertokens="inputWrapper ">
                                    {/* <Input type="email" name="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} /> */}
                                    <input
                                        autoComplete="email"
                                        data-supertokens="input"
                                        className="supertokens-input"
                                        type="email"
                                        name="email"
                                        placeholder="Email address"
                                        value={email}
                                        required
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    
                        <div data-supertokens="formRow ">
                            <button onClick={signUpClicked} type="submit" data-supertokens="button" disabled={!email}>הרשמה</button>
                        </div>
                    </form>
                </div>

            </div>
        </AuthPageWrapper>
    )
}
