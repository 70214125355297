import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { sendPasswordResetEmail } from "supertokens-web-js/recipe/emailpassword";
import DeleteUser from './DeleteUser';
import { toast } from 'react-toastify';
import { roleLabelMaper } from '../../service/AppContext';


export const toLocalDate = (timeStemp) => {
    let intl = new Intl.DateTimeFormat('he', { month: "short", day: "numeric", year: "numeric", time: "numeric" })
    return intl.format(timeStemp)
};
export default function User({ email, phone_number, recipe_id, recipe_ids, third_party_info, time_joind, user_id, removeUser,roles }) {

    const [deleteUser, setDeleteUser] = useState(false);

    const navigate = useNavigate();

    const handleDetailsClick = () => {
        navigate('/users/' + user_id)
    }

    const handleResetPassword = async () => {
        let response = await sendPasswordResetEmail({
            formFields: [{
                id: "email",
                value: email
            }]

        })
        if (response.status in ["FIELD_ERROR", "PASSWORD_RESET_NOT_ALLOWED"])
            console.error(response)
        else
            toast('מייל איפוס סיסמה נשלח')
    }
    
    const handleDeleteUser =  () => {
        removeUser();
        setDeleteUser(false)
        toast('משתמש הוסר מהמערכת')
        }
    


    return (
        <>
            <tr className="user-row">

                <td>
                    <div onClick={handleDetailsClick} className="user-info">
                        <div className="main" title="yohay+test@happyflow.co.il">{email}</div>
                    </div>
                </td>
                <td>
                    {/* <div className="pill emailpassword false"><span>{recipe_id}</span></div> */}
                    {(roles) && <div className="user-role">{roles?.map((role, idx) => <div className="tenant-pill" key={idx}>{roleLabelMaper[role]}</div>)}</div>}
                </td>
                <td>
                    <div className="user-date">{toLocalDate(time_joind)}</div>
                </td>
                <td>
                    <div className="user-row-select-menu">
                        <button className="user-row-select-button">
                            <img src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/chevron-down.svg" alt="Open user detail" className="img-normal" />
                            <img src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/chevron-up-selected.svg" alt="Open user detail" className="img-hover" />
                        </button>
                        <div className="user-row-select-popup">
                            <div className="panel">
                                <button onClick={handleDetailsClick} className="user-row-select-popup-item button flat undefined">
                                    <img className="img-normal" src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/people.svg" alt="View Details" />
                                    <img className="img-hover" src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/people-opened.svg" alt="View Details" />
                                    <span>פרטים</span>
                                </button>
                                <button onClick={handleResetPassword} className="user-row-select-popup-item button flat undefined">
                                    <img className="img-normal" src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/lock.svg" alt="Change Password" />
                                    <img className="img-hover" src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/lock-opened.svg" alt="Change Password" />
                                    <span>איפוס סיסמה</span>
                                </button>
                                {/* <button className="user-row-select-popup-item button flat undefined">
                                <img className="img-normal" src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/mail.svg" alt="Change Email" />
                                <img className="img-hover" src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/mail-opened.svg" alt="Change Email" />
                                <span>עדכון מייל</span>
                            </button> */}
                                <button onClick={() => setDeleteUser(true)} className="user-row-select-popup-item button flat delete">
                                    <img className="img-normal" src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/trash.svg" alt="Delete user" />
                                    <img className="img-hover" src="https://cdn.jsdelivr.net/gh/supertokens/dashboard@v0.7/build/static/media/trash-opened.svg" alt="Delete user" />
                                    <span>מחיקת משתמש</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            {(deleteUser) && <DeleteUser user_id={user_id} email={email} onCancel={() => setDeleteUser(false)} onConfirm={handleDeleteUser} />}
        </>

    )
}
