import { signOut } from "supertokens-auth-react/recipe/session";
import { useNavigate,Link } from "react-router-dom";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import './header.css'
import Sidebar from "./Sidebar";
import { useState } from "react";
import AuthMenuWrapper from "../AuthWrapper/AuthMenuWrapper";



export default function Header({menuItems}) {

  const sessionContext = useSessionContext();  
  const logedIn = sessionContext?.doesSessionExist;
  
  const navigate = useNavigate();


  async function logoutClicked() {
    await signOut();
    navigate("/auth");
  }
  async function loginClicked() {
    navigate("/auth");
  }

  const [openSidebar,setOpenSidebar] =useState(false)

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  }


  return (
    <header className="header" >
      <Sidebar isOpen={openSidebar}
        handleCloseSidebar={handleCloseSidebar}
        links={(logedIn) ?menuItems : []} />

      <div className="header-container" dir='rtl'>
        <img className="logo" src="./logo.png" alt='logo' />
        <div className="section-left">
          <Link className="header-link" to='/'>דף הבית</Link>
          {(logedIn) ?
            <>
              {menuItems?.map(link => (link.roles) ?
                <AuthMenuWrapper key={link.to} roles={link.roles}><Link  className="header-link" to={link.to}>{link.label}</Link></AuthMenuWrapper> :
                <Link key={link.to} className="header-link" to={link.to}>{link.label}</Link>
              )}            
              <button className="round-button" onClick={logoutClicked}>התנתק</button>
            </>
            :
            <button className="round-button" onClick={loginClicked}>כניסה</button>
            
          }
          {/* <button className="round-button" onClick={()=>{setOpenSidebar(prev=>!prev)}}>handleCloseSidebar</button> */}
          <img className="menu-icon" src="./menu_icon.PNG" alt="burger"  onClick={()=>{setOpenSidebar(prev=>!prev)}}/>
        </div>
      </div>
    </header>
  )
}
